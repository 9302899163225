<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <div>
      <h1>Tasa Variable</h1>
    </div>

    <v-row>
      <v-col md="3">
        <template>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatDateInicio"
                label="Desde fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaInicio"
              @input="menu1 = false"
              locale="es-es"
            ></v-date-picker>
          </v-menu>
        </template>
      </v-col>
      <v-col md="3">
        <template>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatDateFinal"
                label="Hasta fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaFinal"
              @input="menu2 = false"
              locale="es-es"
            ></v-date-picker>
          </v-menu>
        </template>
      </v-col>
      <v-col
        md="1"
        style="
          display: flex;
          justify-content: space-between !important;
          align-items: center;
        "
      >
        <v-btn
          :disabled="fechaInicio === ''"
          color="primary"
          class="mt-3 mr-2"
          :loading="loading"
          @click="promedio"
          >Promedio
        </v-btn>

        <v-btn
          :disabled="!this.tasa"
          color="primary"
          class="mt-3 mr-2"
          :loading="loadingExcel"
          @click="exportarExcel()"
          >Exportar Excel</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ListaTasa :items="tasa"></ListaTasa>
      </v-col>
      <v-col>
        <v-card :loading="loading" v-if="tasa" max-width="344">
          <v-card-text>
            <div>{{ promedioTasas.toFixed(3) }}</div>
            <p class="text-h4 text--primary">
              {{ promedioTasas.toFixed(2) }} %
            </p>
            <p>
              Se calculó el promedio entre un total de
              {{ tasa.length }} periodos
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="false" text color="deep-purple accent-4">
              Ver Lista
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import ListaTasa from '@/components/ListaTasa.vue';
import { SIMULADOR_URL } from '@/common/config';
import xlsx from 'xlsx';

export default {
  name: 'TasaVariable',
  components: {
    ListaTasa,
  },
  data: () => ({
    dialog: false,
    menu1: false,
    menu2: false,
    loading: false,
    loadingExcel: false,
    fechaInicio: '',
    tasa: false,
    promedioTasas: '',
    fechaFinal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Tasa Variable',
        disabled: true,
        href: '/',
      },
    ],
  }),
  computed: {
    formatDateFinal() {
      return this.fechaFinal
        ? moment(this.fechaFinal).format('DD-MM-YYYY')
        : '';
    },
    formatDateInicio() {
      return this.fechaInicio
        ? moment(this.fechaInicio).format('DD-MM-YYYY')
        : '';
    },
  },
  methods: {
    async promedio() {
      this.loading = true;
      const url = `${SIMULADOR_URL}tasa_variable_promedio`;
      const data = {
        desde: this.fechaInicio,
        hasta: this.fechaFinal,
      };
      axios
        .post(url, data, this.headers)
        .then((result) => {
          const { promedio, tasas } = result.data;

          this.promedioTasas = promedio * 100;
          this.tasa = tasas.map((elem) => ({
            fecha: (elem.fecha * 100).toFixed(2),
            id: elem.id,
          }));
          this.loading = false;
        })
        .catch(
          (e) => (
            this.$swal.fire({
              icon: 'error',
              title: 'Error en las fechas seleccionadas!!',
              timer: 3000,
            }),
            (this.tasa = false),
            (this.fechaInicio = '')
          )
        );
      this.loading = false;
    },
    async exportarExcel() {
      this.loadingExcel = true;

      let list = this.tasa.map((cc) => ({ Tasa: cc.fecha, Fecha: cc.id }));
      let nombre = `Listado de Tasas - ${this.fechaInicio}, ${this.fechaFinal}`;
      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, 'CC');
      xlsx.writeFile(wb, `${nombre}.xlsx`);
      this.loadingExcel = false;
    },
  },
  created() {},
};
</script>
<style>
.v-date-picker-table--date td {
  padding: 0px !important;
}
</style>
