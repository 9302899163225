<template>
<v-card>
    <v-card-title>Listado de Tasas</v-card-title>
    <v-card-subtitle>Banco Nación</v-card-subtitle>
    <v-list
    v-if="items.length > 0"
    style="max-height: 200px"
    class="overflow-y-auto"
>
 <v-list-item two-line v-for="item in items" :key="item.id">
      <v-list-item-content>
        <v-list-item-title>{{item.fecha}}</v-list-item-title>
        <v-list-item-subtitle>{{item.id}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
</v-list>
</v-card>
</template>
<script>
export default {
  name: 'ListaTasa',
  props: ['items'],
};
</script>
